import React from 'react'
import projectservice from '../../assets/images/projects/certus/display.jpg'


const Details = () => {
    return (
        <section className="services-details-area ptb-100" style={{backgroundColor:'white'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                       
                        <div className="services-details-desc">
                            <h1> Features </h1>
                            
                                <div className="content">          
                                     <h3>Printer Setups </h3>
                                        <ul>
                                            <li>Can add multiple wrist band printer details used for printing.</li>

                                        </ul>
                                </div>

                           
                                <div className="content">  
                                    <h3>Customization</h3>
                                        <ul>
                                            <li>Selection of Patient details required on the Wrist Band.</li>
                                            <li>Barcode type to be printed on the band .</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Wrist Band Printing </h3>
                                        <ul>
                                            <li>View list of registered patients pending for Wrist Band printing.</li>
                                            <li>Reprinting of wrist bands.</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Productivity Analysis </h3>
                                        <ul>
                                            <li>Analyze each employee productivity in the selected duration.</li>
                                        </ul>
                                </div>                                  
                        </div>
                    </div>    
                                
                    <div className="col-lg-6 col-md-12">
                    <img src={projectservice} alt="side image" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Details