import React from 'react'
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const Banner = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

     const [display, setDisplay] = React.useState(false);

     React.useEffect(() => {
         setDisplay(true);
     }, [])
 

return (
    <React.Fragment>

{display ? <OwlCarousel 
    className="home-slides owl-carousel owl-theme"
    {...options}
> 

    <div className="main-banner-item item-bgCC1">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">Certus</span>
                        <h1>Effective patient identification ensures quality health care.</h1>
                        <p>customized solution for patient wrist band printing</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                   
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="main-banner-item item-bgCC2">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">Certus</span>
                        <h1>Improve Patient ID accuracy and operational efficiency of point-of-care services</h1>
                        <p>Scan able 2 D data matrix and linear barcodes ensures accurate patient identification for ultimate safety.</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                   
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="main-banner-item item-bgCC3">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">Certus</span>
                        <h1>Effortless Integration to HMIS </h1>
                        <p>Registered Patient details easily transferred ensuring effective patient identification and tracking. .</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                            </Link>
                   
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>


   

</OwlCarousel> : ''}
</React.Fragment>
)
}

export default Banner